

































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
export default Vue.extend({
  data() {
    return {
      loading: false,
      pageNum: 1,
      pageSize: 10,
      total: 1,
      columns: [
        {
          title: '部门名称',
          key: 'name',
        },
        {
          title: '区域编码',
          key: 'areaId',
        },
        {
          title: '区域名称',
          key: 'areaName',
        },
        {
          title: '上级单位',
          key: 'parentName',
        },
        {
          title: '操作',
          slot: 'action',
          // width: 400
        },
      ],
      data: {
        total: 0,
        list: [],
      },
    }
  },
  methods: {
    getData() {
      this.loading = true
      const { pageNum, pageSize } = this
      const params = { pageNum, pageSize }
      request.get('/api/sys/office/page', params)
      .then((data) => {
        this.data = data
        this.loading = false
      })
    },
    deleteItem(id: string) {
      request.del(`/api/sys/office/${id}`)
      .then((res) => {
        this.$Message.success('删除成功!')
        this.getData()
      }).catch((errorMsg) => {
        this.$Message.error(errorMsg)
      })
    },
    /* 改变pageNum */
    changPageNum(num: number) {
      this.pageNum = num
      this.getData()
    },
  },
  mounted() {
    this.getData()
  },
})
